import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_COMENT,
    DELETE_COMENT,
    COMENT_FORM_TOOGLE_LOADING,
    SET_COMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_COMENT,
} from "../constants";
import { formatComents } from "./settingsActionsUtils";

/* COMENT LIST */
export const fetchComents = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.coments.list)
            .then((response) => {
                const coments = formatComents(response.data);
                dispatch({
                    type: SET_COMENT_LIST,
                    payload: keyBy(coments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// COMENTS
export const createComent = (newComent) => {
    return async (dispatch) => {
        dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.coments.create, newComent)
            .then((response) => {
                const coment = formatComents(response.data);
                dispatch({ type: ADD_NEW_COMENT, payload: coment });
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Comentario creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateComent = (updatedComent) => {
    return async (dispatch) => {
        dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.coments.edit}${updatedComent && updatedComent._id}`, updatedComent)
            .then((response) => {
                const coment = formatComents(response.data);
                dispatch({ type: UPDATE_COMENT, payload: coment });
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Comentario actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteComents = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.coments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_COMENT, payload: Ids });
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Comentario eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
