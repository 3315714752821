import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_DAY,
    DELETE_DAY,
    DAY_FORM_TOOGLE_LOADING,
    SET_DAY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_DAY,
} from "../constants";
import { formatDays } from "./settingsActionsUtils";

/* DAY LIST */
export const fetchDays = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.days.list)
            .then((response) => {
                const days = formatDays(response.data);
                dispatch({
                    type: SET_DAY_LIST,
                    payload: keyBy(days, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// DAYS
export const createDay = (newDay) => {
    return async (dispatch) => {
        dispatch({ type: DAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.days.create, newDay)
            .then((response) => {
                const day = formatDays(response.data);
                dispatch({ type: ADD_NEW_DAY, payload: day });
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Día creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateDay = (updatedDay) => {
    return async (dispatch) => {
        dispatch({ type: DAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.days.edit}${updatedDay && updatedDay._id}`, updatedDay)
            .then((response) => {
                const day = formatDays(response.data);
                dispatch({ type: UPDATE_DAY, payload: day });
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Día actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteDays = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: DAY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.days.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_DAY, payload: Ids });
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Día eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DAY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
