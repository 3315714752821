import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LIKE,
    DELETE_LIKE,
    LIKE_FORM_TOOGLE_LOADING,
    SET_LIKE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LIKE,
} from "../constants";
import { formatLikes } from "./settingsActionsUtils";

/* LIKE LIST */
export const fetchLikes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.likes.list)
            .then((response) => {
                const likes = formatLikes(response.data);
                dispatch({
                    type: SET_LIKE_LIST,
                    payload: keyBy(likes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LIKES
export const createLike = (newLike) => {
    return async (dispatch) => {
        dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.likes.create, newLike)
            .then((response) => {
                const like = formatLikes(response.data);
                dispatch({ type: ADD_NEW_LIKE, payload: like });
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Like creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLike = (updatedLike) => {
    return async (dispatch) => {
        dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.likes.edit}${updatedLike && updatedLike._id}`, updatedLike)
            .then((response) => {
                const like = formatLikes(response.data);
                dispatch({ type: UPDATE_LIKE, payload: like });
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Like actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLikes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.likes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LIKE, payload: Ids });
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Like eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LIKE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
