import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BUY,
    DELETE_BUY,
    BUY_FORM_TOOGLE_LOADING,
    SET_BUY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BUY,
} from "../constants";
import { formatBuys } from "./settingsActionsUtils";

/* BUY LIST */
export const fetchBuys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.buys.list)
            .then((response) => {
                const buys = formatBuys(response.data);
                dispatch({
                    type: SET_BUY_LIST,
                    payload: keyBy(buys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BUYS
export const createBuy = (newBuy) => {
    return async (dispatch) => {
        dispatch({ type: BUY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.buys.create, newBuy)
            .then((response) => {
                const buy = formatBuys(response.data);
                dispatch({ type: ADD_NEW_BUY, payload: buy });
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Se ha realizado la petición de compra.",
                    },
                });
                return response;
            })
            .catch((err) => {
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBuy = (updatedBuy) => {
    return async (dispatch) => {
        dispatch({ type: BUY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.buys.edit}${updatedBuy && updatedBuy._id}`, updatedBuy)
            .then((response) => {
                const buy = formatBuys(response.data);
                dispatch({ type: UPDATE_BUY, payload: buy });
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Compra actualizado.",
                    },
                });
                return response && response;
            })
            .catch((err) => {
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBuys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BUY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.buys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BUY, payload: Ids });
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Compra eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
