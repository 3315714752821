// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	countrys: {
    create: `/country`,
    edit: `/country/`,
    delete: `/country/`,
    list: `/country`,
  },
  buys: {
    create: `/buy`,
    edit: `/buy/`,
    delete: `/buy/`,
    list: `/buy`,
  },
  likes: {
    create: `/like`,
    edit: `/like/`,
    delete: `/like/`,
    list: `/like`,
  },
  coments: {
    create: `/coment`,
    edit: `/coment/`,
    delete: `/coment/`,
    list: `/coment`,
  },
  days: {
    create: `/day`,
    edit: `/day/`,
    delete: `/day/`,
    list: `/day`,
  },
  travels: {
    create: `/travel`,
    edit: `/travel/`,
    delete: `/travel/`,
    list: `/travel`,
  },
  activitys: {
    create: `/activity`,
    edit: `/activity/`,
    delete: `/activity/`,
    list: `/activity`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
    conectregister: `/user/conectregister`,
    forgotPassword: `/user/forgotPassword`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/users`,
    contact: `/contact`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
